exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-formulas-jsx": () => import("./../../../src/pages/en/formulas.jsx" /* webpackChunkName: "component---src-pages-en-formulas-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-success-jsx": () => import("./../../../src/pages/en/success.jsx" /* webpackChunkName: "component---src-pages-en-success-jsx" */),
  "component---src-pages-formulas-jsx": () => import("./../../../src/pages/formulas.jsx" /* webpackChunkName: "component---src-pages-formulas-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */)
}

